/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  color: #ef5350;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  color: #ef5350;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
  color: #fff;
  background: #ef5350;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #ef5350;
  cursor: pointer;
  color: #000;
}

.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid#EF5350;
  border-top-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td.rdtSwitch {
  text-align: center;
  color: #fff;
  background: #ef5350;
  padding-top: 5px;
  padding-bottom: 5px;
}

.rdtPicker .rdtSwitch:hover {
  cursor: pointer;
  color: #000;
  background: #ef5350;
  padding-top: 5px;
  padding-bottom: 5px;
}

/*Custom CSS*/

.rdtPicker .rdtConfirm {
  text-align: center;
  background: #fff;
  color: #a50d1d;
  border-style: solid;
  border-width: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 700;

  padding-right: 10%;
  padding-left: 10%;
  margin-right: 10%;
  margin-left: 10%;
  width: 80%;
  margin-bottom: 30px;
}

.rdtPicker .rdtConfirm:hover {
  cursor: pointer;
  color: #eee;
  background: #a50d1d;
  padding-top: 5px;
  padding-bottom: 5px;
}
