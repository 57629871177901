.fa-folder {
  color: purple;
}
.fa-folder-o {
  color: blue;
}

.fa-file-pdf {
  color: red;
}
.fa-file-excel {
  color: gray;
}
.fa-file-audio {
  color: orange;
}
.filename {
  padding: 5px;
  color: black;
  font-size: 16px;
}

.custom-tooltip-header {
  position: absolute;
  /* top: 20px; */
  /* width: 140px;
  height: 30px; */
  /* overflow: hidden; */
  pointer-events: none;
  /* transition: opacity 0.5s; */

  max-width: 200px;
  line-height: 22px;
  background: rgb(#a51d0d, 0.8);
  box-shadow: 0 8px 10px 1px rgb('black', 0.14),
    0 3px 14px 2px rgb('black', 0.12), 0 5px 5px -3px rgb('black', 0.2);

  padding: 5px 15px;
  /* justify-content: center;
  align-items: center;
  display: flex; */

  border: none;
  border-radius: 5px;

  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  line-break: auto;
}

.custom-tooltip {
  position: absolute;
  width: 220px;
  height: 50px;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;

  padding: 5px 15px;

  line-height: 22px;
  /* background: rgb(#a51d0d, 0.9); */
  box-shadow: 0 8px 10px 1px rgb('black', 0.14),
    0 3px 14px 2px rgb('black', 0.12), 0 5px 5px -3px rgb('black', 0.2);

  /* justify-content: center;
  align-items: center;
  display: flex; */

  border: none;
  border-radius: 5px;

  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  line-break: auto;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0.9;
}

.custom-tooltip p {
  margin: 5px;
}

/* .custom-tooltip p:first-of-type {
  font-weight: bold;
} */
